@import '~@astrid/components/dist/scss/utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.25rem 1rem 0;
  max-width: 100%;
}

.box {
  background-color: white;
  padding: 2rem 4rem;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-style: dashed;
  border-bottom-width: 2px;
  border-bottom-color: $very-light-grey;

  @include breakpoint(xs) {
    padding: 1.5rem 2rem;
  }

  &__bottom_section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 4rem;
    background-color: $very-light-pink;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    @include breakpoint(xs) {
      padding: 1.5rem 2rem;
    }
  }
}

.error {
  color: red;
  margin-bottom: 1rem;
}

.button {
  width: 100%;
  font-size: 1.25rem;
  margin-top: 1.5rem;
}

.button__bottom {
  @extend .button;
  margin-top: 0;
}

.margin {
  margin: 1rem 0;
  text-align: center;
}

.margin_xl {
  margin: 3rem 0;
}

.success_container {
  width: 100%;
  flex: 1;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.link {
  color: black;
}
