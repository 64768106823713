@import '~@astrid/components/dist/scss/globals';
@import '~@astrid/components/dist/scss/utils';

@font-face {
  font-family: 'Lexend';
  src: url('~@astrid/components/dist/fonts/LexendDeca-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  src: url('~@astrid/components/dist/fonts/WorkSans-ExtraBold.ttf') format('truetype');
}

html {
  font-size: 16px;

  @include breakpoint(sm) {
    font-size: 13px;
  }
}

dl {
  margin: 0;
}

p {
  margin: 0;
}

/* Astrid global overrides */
body {
  background-color: #f3f7ff;
  overflow: auto;
}

#root {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  overflow-y: auto;
  background-color: $blue-grey;
}
