@import '~@astrid/components/dist/scss/utils';

.container {
  padding: 16px 0 16px 23%;
  background-color: white;
  border-radius: 16px;
  border: 2px solid $light-grey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin: 6px 0;
  width: 100%;

  transition: 250ms all;

  &:hover {
    transform: scale(1.03);
  }
}

.text {
  margin-left: 8px;
}
