@import '~@astrid/components/dist/scss/utils';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: 2px solid $light-grey;
  flex-direction: row;
  cursor: pointer;
  border-radius: 16px;
  padding: 1rem 1.5rem;
  width: 100%;
  margin: 0.5rem 0;
  transition: 250ms all;

  &:hover {
    transform: scale(1.03);
  }

  &__selected {
    @extend .container;
    border-color: $robin-s-egg;
  }
}

.inner_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
