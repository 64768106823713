@import '~@astrid/components/dist/scss/utils';

.header {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button {
  width: 100%;
  font-size: 1.25rem;
}

.input_container {
  margin-bottom: 1rem;
  width: 100%;
}

.margin {
  margin-top: 1rem;
}

.error {
  color: red;
  font-weight: bold;
  margin-bottom: 1rem;
}
